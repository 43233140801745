<template>
<!--  simple collapsible - not icons or state -->
  <section :class="[show_content ? 'pok-collapsible-open' : '', 'pok-collapsible']">
    <div class="collapse-box-header" v-on:click="toggle">
      <h2>
        <slot name="collapse-title-slot">Default Collapse Title (Slot)</slot>
      </h2>
    </div>
    <div class="collapse-box-content">
        <slot name="collapse-content-slot">Default Collapse Content (Slot)</slot>
    </div>

<!--    <div class="collapse-box-content">-->
<!--      <div class="content-inner text">-->
<!--        <slot name="collapse-content-slot">Default Collapse Content (Slot)</slot>-->
<!--      </div>-->
<!--    </div>-->

  </section>
</template>

<script>
export default {
  name: 'PokCollapsibleBasic',
  methods: {
    toggle() {
      this.show_content = !this.show_content
    }
  },
  data() {
    return {
      show_content: false
    }
  }
}
</script>