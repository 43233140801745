// store.js
import { reactive } from 'vue'

export const store = reactive({
    app_view_class: '',
    set_app_view_class(class_name='nav-active') {
        this.app_view_class = class_name
        if(this.app_view_class == 'detail-open'){
            document.querySelector('body').style.overflowY = 'hidden';
        }else{
            document.querySelector('body').style.overflowY = '';
        }
    },
    main_scrolled_class: '',
    set_main_scrolled_class(class_name='main-scrolled') {
        this.main_scrolled_class = class_name
    },
    contact_overlay_open_class: '',
    set_contact_overlay_open_class(class_name='contact-overlay-open') {
        this.contact_overlay_open_class = class_name
        if(class_name == 'contact-overlay-open'){
            document.querySelector('body').style.overflowY = 'hidden';
        }else{
            document.querySelector('body').style.overflowY = '';
        }
    }
})