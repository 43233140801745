<template>
  <div id="main-wrapper" :class="[store.app_view_class, store.main_scrolled_class, store.contact_overlay_open_class]">

    <header class="main-header" :class="{ 'header-init': is_init }">
      <router-link to="/">
        <div class="logo-wrapper">
          <div class="logo">
            <img src="/static/images/logo_pokus_blank_white.svg" alt="pokus logo"/>
          </div>
        </div>
      </router-link>
        <div class="info info-desktop desktop-only">


          <div class="contact text">
            Ron Widmer
            <br/><a href="mailto:&#x72;&#x6F;n&#x40;pokus.c&#x68;?subject=Hello&nbsp;Pokus&body=Hello&nbsp;Pokus">ron@pokus.ch</a>
            <br/>
            <br/>simple & co gmbh
            <br/>Hardturmstrasse 261
            <br/>8005 Zürich
          </div>

        </div>
    </header>


    <div class="general">
<!--      <div class="scroll-container">-->
<!--        <div class="scrollable">-->
      <perfect-scrollbar>
          <PokCollapsibleBasic>
            <template v-slot:collapse-title-slot>Digitale Konzepte &amp;&nbsp;WebApps</template>
            <template v-slot:collapse-content-slot>
              <div class="content-inner text">
                <div class="skills font-size-medium">
                  <div class="skill-pair">
                    UX Design / Prototyping
                  </div>
                  <div class="skill-pair">
                    Konzeptentwicklung / Beratung
                  </div>
                  <div class="skill-pair">
                    WebApps / SPA / CMS Websites
                  </div>
                  <div class="skill-pair">
                    Programmierung
                  </div>
                  <div class="skill-pair">
                    Datenbanken / Interfaces
                  </div>
                  <div class="skill-pair">
                    Betrieb / Support
                  </div>
                  <div class="skill-pair">
                    Physical Computing / IoT
                  </div>
                  <div class="skill-pair">
                    Creative Coding
                  </div>
                  <div class="skill-pair">
                    Interaktive Installationen
                  </div>
                </div>

                <div class="bar-small"></div>
                <p class="font-size-large">
                  Zusammen mit unseren Kunden entwickeln wir überzeugende Webapps.
                </p>
                <p class="font-size-medium margin-top-large">
                  Verrückte Idee oder ein konkretes&nbsp;Anliegen?
                  <br>Oder einfach eine erste Einschätzung willkommen?
                </p>
                <div>
                  <a class="get-in-touch" href="mailto:&#x72;&#x6F;n&#x40;pokus.c&#x68;?subject=Hello&nbsp;pokus&body=Hello&nbsp;pokus">
                    <div class="button font-size-medium font-bold">Say Hello</div>
                  </a>
                </div>


              </div>
            </template>
          </PokCollapsibleBasic>
          <PokCollapsibleBasic>
            <template v-slot:collapse-title-slot>Crew</template>
            <template v-slot:collapse-content-slot>
              <div class="team-pictures">
                <div class="team-member">
                  <img src="/static/images/team/ron_widmer.png" alt="">
<!--                  <img src="/static/images/team/IMG_7372_Portrait_Ron211128.jpg" alt="">-->
                </div>
                <div class="team-member">
                  <img src="/static/images/team/cv_rm_mueller.jpg" alt="">
                </div>
                <div class="team-member">
                  <img src="/static/images/team/markus_seiler.jpg" alt="">
                </div>
                <div class="team-member">
                  <img src="/static/images/team/marco_klingmann_400x400.jpg" alt="">
                </div>

              </div>
              <div class="content-inner margin-top text">
                <div class="team">
                  <h3>Ron Widmer</h3>
                  <p class="team-member-description">
                    Beratung & Konzept / UX-Design / Web Development
                  </p>
                  <h3>Ralph Müller</h3>
                  <p class="team-member-description">
                    Softwareentwicklung / Sys-Admin
                  </p>
                  <h3>Markus Seiler</h3>
                  <p class="team-member-description">
                    Beratung / Analyse / Projektleitung
                  </p>
                  <h3>Marco Klingmann</h3>
                  <p class="team-member-description">
                    Konzept & Entwicklung (iOS, Android)
                  </p>
                  <p>

                  </p>
                  <h3>
                    Technologien
                  </h3>
                  <div class="skills">
<!--                    <div class="skill-pair">-->
<!--                      Konzeptentwicklung-->
<!--                    </div>-->
<!--                    <div class="skill-pair">-->
<!--                      Strategie & Beratung-->
<!--                    </div>-->
<!--                    <div class="skill-pair">-->
<!--                      UX-Design & Prototyping-->
<!--                    </div>-->
<!--                    <div class="skill-pair">-->
<!--                      Web Development & Physical Computing-->
<!--                    </div>-->
<!--                    <div class="skill-pair">-->
<!--                      Schnittstellen-->
<!--                    </div>-->

                    <div class="skill-pair">
                      Python, Django
                    </div>
                    <div class="skill-pair">
                      PostgreSQL & MySQL
                    </div>
                    <div class="skill-pair">
                      Html/Scss & Javascript
                    </div>
                    <div class="skill-pair">
                      Vue, React/React Native
                    </div>
                    <div class="skill-pair">
                      Arduino, Raspberry Pi
                    </div>
                    <div class="skill-pair">
                      IOS, Android, C/C++
                    </div>
                    <div class="skill-pair">
                      Linux
                    </div>
                  </div>
<!--                  <div class="bar-small"></div>-->
<!--                  <p class="font-size-medium font-bold">-->
<!--                    Zusammen realisieren wir überzeugende Webapps.-->
<!--                  </p>-->

                </div>
              </div>
            </template>
          </PokCollapsibleBasic>
          <PokCollapsibleBasic>
            <template v-slot:collapse-title-slot>Kunden</template>
            <template v-slot:collapse-content-slot>
              <div class="content-inner text">
<!--                <p class="content-side-padding">-->
<!--                  Diese und weitere Kunden zählen seit Jahren auf unser Know-How-->
<!--                </p>-->
                <div class="client-logos">

                  <div class="logo-item"><img src="/static/images/client_logo_align_center/client_logo_3.svg" alt="logo 3"/></div>


                  <div class="logo-item"><img src="/static/images/client_logo_align_center/client_logo_2.svg" alt="logo 2"/></div>
                  <div class="logo-item"><img src="/static/images/client_logo_align_center/client_logo_1.svg" alt="logo 1"/></div>

                  <div class="logo-item"><img src="/static/images/client_logo_align_center/client_logo_sma_pokus.svg" alt="logo sma"/></div>

                  <div class="logo-item"><img src="/static/images/client_logo_align_center/client_logo_4.svg" alt="logo 4"/></div>
                  <div class="logo-item"><img src="/static/images/client_logo_align_center/client_logo_5.svg" alt="logo 5"/></div>
                </div>
              </div>
            </template>
          </PokCollapsibleBasic>

      </perfect-scrollbar>
<!--        </div>-->
<!--      </div>-->
    </div>


    <nav class="project-nav">
<!--      <div class="scroll-container">-->
<!--        <div class="scrollable">-->
      <perfect-scrollbar>
          <div class="project-item" v-for="(item) in project_list" :key="item.id">
            <router-link :to="{ name: 'project_detail', params: { project_id: item.id } }">
              <div className="project-item">
                <div className="teaser-image FlexEmbed" :style="{backgroundImage: `url('${item.main_image_url}')`}"></div>
                <div class="text-block" v-html="item.title"></div>
              </div>
            </router-link>
          </div>
      </perfect-scrollbar>
<!--        </div>-->
<!--      </div>-->

    </nav>
    <!--    <Link to={'/'} >-->
    <!--      <div class="nav-activator"></div>-->
    <!--    </Link>-->

    <div class="main-content-wrapper">
      <router-view/>
    </div>
    <router-link to="/">
      <div class="close-button">
        <img src="/static/images/icon_close_white_space.svg" alt=""/>
      </div>
    </router-link>
    <div class="contact-button" @click="store.set_contact_overlay_open_class()">
      <img src="/static/images/icon_contact.svg" alt=""/>
    </div>
    <div class="contact-overlay">
        <div class="info">

          <h2 class="font-size-medium"><a href="mailto:&#x72;&#x6F;n&#x40;pokus.c&#x68;?subject=Hello&nbsp;Pokus&body=Hello&nbsp;Pokus">Get in Touch</a></h2>
          <div class="contact text">
            Ron Widmer
            <br/><a href="mailto:&#x72;&#x6F;n&#x40;pokus.c&#x68;?subject=Hello&nbsp;Pokus&body=Hello&nbsp;Pokus">ron@pokus.ch</a>
            <br/>
            <br/>simple & co gmbh
            <br/>Hardturmstrasse 261
            <br/>8005 Zürich
          </div>

        </div>
      <div class="close-button-contact" @click="store.set_contact_overlay_open_class('')">
        <img src="/static/images/icon_close_white_space.svg" alt=""/>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import {store} from '@/store.js'
import PokCollapsibleBasic from "@/components/PokCollapsibleBasic";

export default {
  name: 'App',
  components: {PokCollapsibleBasic},
  data() {
    return {
      store,
      project_list: [],
      is_init: true,
    }
  },
  methods: {
    handleScroll() {
      window.onscroll = () => {
        if(window.scrollY > 150){
          store.set_main_scrolled_class('main-scrolled')
        }else{
          store.set_main_scrolled_class('')
        }
      };
    },

    removeHeaderInitClass() {
      this.is_init = false
    }

  },
  async created() {
    this.handleScroll();
    // GET request using fetch with async/await
    let url = "/api/project/"
    let host = location.host;
    if(['pokus.ch', 'www.pokus.ch', 'new.pokus.ch'].includes(host)){
      url = "https://"+host+url; // force https for production
    }
    const response = await fetch(url);
    const data = await response.json();
    this.project_list = data;
    console.log(this.project_list)
    // this.loaded = true;
    setTimeout(this.removeHeaderInitClass, 1200)
  }
}
</script>

