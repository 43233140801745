import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
// import VScrollLock from 'v-scroll-lock'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

createApp(App).use(router).use(PerfectScrollbar).mount('#app')
// createApp(App).use(router).use(PerfectScrollbar).use(VScrollLock).mount('#app')

