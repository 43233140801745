<template>
  <div class="home">

  </div>
</template>

<script>
// @ is an alias to /src
import { store } from '@/store.js'

export default {
  name: 'HomeView',
  data() {
    return {
      store
    }
  },
  mounted() {
    store.set_app_view_class('nav-active')
  }
}
</script>
